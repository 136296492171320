<template>
  <div class="file">
    <el-container class="main-container">
      <el-aside width="100px" class="aside">
        <div class="Menu">
          <div :class="menuKey == '' ? 'menuItem menuActive' : 'menuItem'" @click="menuChange({})">
            全部
          </div>
          <div :class="menuKey == item.DicKey ? 'menuItem menuActive' : 'menuItem'" v-for="(item, index) in Menu"
            :key="item.DicKey" @click="menuChange(item, index)">
            {{ item.DicName }}
          </div>
        </div>
      </el-aside>
      <el-container>
        <el-header class="header" height="auto">
          <div style="display: flex;">
            <div style="width: 60%;">
              <el-form ref="form" :model="searchForm" label-width="80px" size="small">
                <el-form-item label="应用名称">
                  <el-radio-group v-model="searchForm.ApplyId" @change="appChange">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button :label="item.Id" v-for="item in appListData" :key="item.Id">{{
                      item.ApplyName }}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="任务">
                  <el-select v-model="searchForm.TaskIds" placeholder="请选择任务" multiple filterable @change="taskChange">
                    <el-option v-for="item in taskListData" :key="item.Id" :label="`${item.Name}【${item.AppyName}】`"
                      :value="item.Id">
                    </el-option>
                  </el-select>

                </el-form-item>
                <el-form-item label="时间范围">
                  <el-date-picker v-model="searchForm.timeData" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="timeChange">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="文件名称">
                  <el-input v-model="searchForm.FileName" style="width: calc(100% - 70px)"></el-input>
                  <el-button type="primary" style="width: 60px;margin-left: 10px;" @click="search">查询</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div style="width: 40%;position: relative;">
              <div style="position: absolute;bottom: 0;right: 0;">

                <div>
                  <el-button size="small" style="margin-right: 5px;" type="primary" @click="share"
                    icon="el-icon-share">分享</el-button>
                  <el-button size="small" type="primary" style="margin-right: 10px;" @click="openshareList"
                    icon="el-icon-share">我的分享</el-button>
                  <el-radio-group size="mini" v-model="viewType">
                    <el-radio-button label="card" :border="false"><i class="el-icon-menu"
                        style="font-size: 16px"></i></el-radio-button>
                    <el-radio-button label="list" :border="false"><i class="el-icon-s-operation"
                        style="font-size: 16px;font-weight: bold"></i></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </el-header>
        <el-main v-show="viewType == 'card'" v-loading="loading">
          <div class="center" v-if="cardList.length > 0">
            <el-card :class="['proListCard', item.checked ? 'proListCardActive' : '']" :body-style="{ padding: '0px' }"
              v-for="(item, index) in cardList" :key="index" @mouseenter.native="item.flag = true"
              @mouseleave.native="item.flag = false" @click.native="cardClick(item)" :title="item.FileName">
              <el-image :src="item.FileType.indexOf('image') !== -1 ? item.FileWebURL : folderImgData(item.FileType)" class="proListImg"
                :fit="item.FileType === 'image' ?'cover': 'scale-down'"></el-image>
              <div style="height: 75px;box-sizing: border-box;">
                <div
                  style="padding: 0 5px;box-sizing: border-box; line-height: 20px;font-weight: bold;font-size: 12px;width: 100%;display: flex;align-items: center;">
                  <div class="ellipsis1"> {{ splitFilename(item.FileName)[0] || '' }}</div>
                  <div>{{ splitFilename(item.FileName)[1] ? '.' + splitFilename(item.FileName)[1] : '' }}</div>

                </div>
                <div style="padding: 0 5px;box-sizing: border-box;line-height: 20px;font-size: 12px;width: 100%;"
                  class="ellipsis1">
                  <span>{{ item.CreateTime ? item.CreateTime.slice(0, 19) : '' || '' }}</span>
                </div>
                <el-row :gutter="10" style="padding: 0 5px;">
                  <el-col :span="12" style="text-align: center;"> <el-button
                      style="padding: 0;line-height: 25px;height: 25px;font-size: 12px;width: 100%;"
                      @click.stop="share(item)" size="small" type="warning" icon="el-icon-share">分享</el-button></el-col>
                  <el-col :span="12" style="text-align: center;" v-if="!item.ReportId"> <el-button
                      style="padding: 0;line-height: 25px;height: 25px;font-size: 12px;width: 100%;"
                      @click.stop="download(item)" size="small" type="info"
                      icon="el-icon-download">下载</el-button></el-col>
                  <el-col :span="12" style="text-align: center;" v-if="item.ReportId">
                    <el-popover placement="top" width="240" trigger="click" content="请选择下载导出格式">
                      <div style="margin-bottom: 10px;">请选择下载导出格式</div>

                      <div style="display: flex;justify-content: center;">
                        <el-button size="mini" type="primary" @click.stop="getWord(item)">Word</el-button>
                        <el-button size="mini" type="primary" @click.stop="getExcel(item)">Excel</el-button>
                        <el-button size="mini" type="primary" @click.stop="download(item)">{{ item.FileType
                          }}</el-button>
                      </div>
                      <el-button slot="reference"
                        style="padding: 0;line-height: 25px;height: 25px;font-size: 12px;width: 100%;" @click.stop=""
                        size="small" type="info" icon="el-icon-download">下载</el-button>
                    </el-popover>


                  </el-col>
                </el-row>


              </div>
              <div style="position: absolute;top: -2px;left: -2px;z-index: 10;" v-show="item.checked">
                <div
                  style="width: 25px;height: 25px;background-color: #D1100B;font-size: 15px;text-align: center;color: #fff;display: flex;align-items: center;justify-content: center;border-radius: 6px;">
                  <i class="el-icon-circle-check"></i>
                </div>
              </div>
            </el-card>
          </div>
          <el-empty description="暂无数据" v-else></el-empty>
        </el-main>
        <el-main v-show="viewType == 'list'" v-loading="loading">
          <div v-if="cardList.length > 0" class="center">
            <el-table :data="cardList" style="width: 100%" border :header-row-style="{
              padding: '10px !important;',
            }" stripe @row-click="rowClick">
              <el-table-column width="50" align="center" label="">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @click.native="stopEvent($event)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="FileName" label="文件名">
              </el-table-column>

              <el-table-column prop="ApplyName" label="应用名称" width="100"></el-table-column>
              <el-table-column prop="TaskName" label="任务名称"></el-table-column>
              <el-table-column prop="CreateTime" label="上传日期" width="180">
                <template slot-scope="scope">
                  {{ scope.row.CreateTime ? scope.row.CreateTime.slice(0, 19) : '' || '' }}
                </template>
              </el-table-column>
              <el-table-column prop="FileSize" label="文件大小" width="150">
                <template slot-scope="scope">
                  {{ scope.row.FileSize / 1024 / 1024 > 1 ? (scope.row.FileSize / 1024 / 1024).toFixed(2) + 'MB' :
                    (scope.row.FileSize / 1024).toFixed(2) + 'KB' }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="140" align="center">
                <template slot-scope="scope">
                  <el-button type="text" @click.stop="share(scope.row)" icon="el-icon-share"
                    style="font-size: 14px;">分享</el-button>
                  <el-button type="text" @click.stop="download(scope.row)" icon="el-icon-download"
                    style="margin-left: 10px;font-size: 14px;" v-if="!scope.row.ReportId">下载</el-button>
                  <el-popover placement="top" width="240" trigger="click" content="请选择下载导出格式" v-if="scope.row.ReportId">
                    <div style="margin-bottom: 10px;">请选择下载导出格式</div>
                    <div style="display: flex;justify-content: center;">
                      <el-button size="mini" type="primary" @click.stop="getWord(scope.row)">Word</el-button>
                      <el-button size="mini" type="primary" @click.stop="getExcel(scope.row)">Excel</el-button>
                      <el-button size="mini" type="primary" @click.stop="download(scope.row)">{{ scope.row.FileType
                        }}</el-button>
                    </div>
                    <el-button slot="reference" style="margin-left: 10px;font-size: 14px;" @click.stop="" size="small"
                      type="text" icon="el-icon-download">下载</el-button>
                  </el-popover>
                  <!-- <el-popconfirm title="请选择导出格式" cancel-button-type="Primary" cancel-button-text="Word"
                    confirm-button-text="Excel" @confirm="getExcel(scope.row)" @cancel="getWord(scope.row)">
                    <el-button size="mini" slot="reference" type="text" @click.stop="" icon="el-icon-download"
                      style="margin-left: 10px;font-size: 14px;">导出
                    </el-button>
                  </el-popconfirm> -->
                </template>
              </el-table-column>
            </el-table>

          </div>
          <el-empty description="暂无数据" v-else></el-empty>
        </el-main>
        <el-footer height="40px" style="display: flex;align-items: center;justify-content: right;">

          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pagination.currentPage" :page-sizes="[40, 80, 160, 320, 500, 1000]"
            :page-size="pagination.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pagination.total"
            background>
          </el-pagination>
        </el-footer>
      </el-container>
    </el-container>
    <el-dialog :visible.sync="qrcodeVisible" width="50%" append-to-body :show-close="false"
      :close-on-click-modal="false">
      <div slot="title">
        <el-row style="height: 33px;text-align: center;font-weight: bold;font-size: 18px;">
          分享文件
          <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;float: right;"
            src="@/assets/delete.png" alt="" @click="qrcodeVisible = false" />
        </el-row>
      </div>
      <div>
        <el-form ref="form" :model="shareForm" label-width="120px" size="small" v-loading="shareFormLoading"
          v-if="!shareInfoFlag">
          <el-form-item label="截止日期：">
            <el-date-picker v-model="shareForm.DeadLine" type="datetime" placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss" :disabled="shareForm.IslongTerm" :picker-options="pickerOptions"
              popper-class="dateime">
            </el-date-picker>
            <el-checkbox v-model="shareForm.IslongTerm" style="margin-left: 20px;">永久有效</el-checkbox>
          </el-form-item>

          <el-form-item label="提取码：" style="margin-top: 40px;">
            <el-radio-group v-model="shareForm.isCode">

              <el-radio :label="true">系统随机生成提取码</el-radio>
              <br>
              <div style="height: 10px;"></div>
              <br>
              <el-radio :label="false">
                <el-input placeholder="请输入4-6位字母或数字提取码" v-model="shareForm.SharingCode" maxlength="6"
                  :disabled="shareForm.isCode"></el-input>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="shareForm.IsContainParam">分享链接自动填充提取码</el-checkbox>

          </el-form-item>
          <el-form-item>
            <el-button style="width: 200px" type="primary" @click="createShareUrl">创建分享链接</el-button>
          </el-form-item>
        </el-form>
        <div style="display: flex;justify-content: space-around;align-items: center;" v-if="shareInfoFlag">
          <div>
            <span style="font-size: 14px;color: #D1100B;"> <i class="el-icon-success"></i> 成功创建分享链接</span><br><br>
            <span>分享链接：</span><br>
            <span style="color: #D1100B;cursor: pointer;" @click="copyUrl(qrcode)"> {{ qrcode }}</span><br>
            <br>
            <span>提取码：</span><span style="color: #D1100B;"> {{ shareInfo.SharingCode }}</span>

            <br>
            <br>
            <el-button type="primary" @click="copyRowUrl(shareInfo)"> 复制链接及提取码</el-button>
          </div>
          <div>
            <vue-qr :text="qrcode" :logoScale="0.2" :size="200"></vue-qr>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="qrcodeVisible = false">关闭</el-button>

      </span>
    </el-dialog>
    <el-drawer title="我的分享" :visible.sync="drawer" :with-header="false" size="70%">

      <div style="padding: 20px; height: 100vh;box-sizing: border-box;">
        <div style="height: calc(100% - 100px);overflow-y: auto;">
          <el-table :data="shareInfoData" border stripe>
            <el-table-column prop="SharingName" label="分享动态"></el-table-column>
            <el-table-column prop="FileSize" label="文件大小" width="120" align="center">
              <template slot-scope="scope">
                {{ scope.row.FileSize / 1024 / 1024 > 1 ? (scope.row.FileSize / 1024 / 1024).toFixed(2) + 'MB' :
                  (scope.row.FileSize / 1024).toFixed(2) + 'KB' }}
              </template>
            </el-table-column>
            <el-table-column prop="FileCount" label="文件数量" width="110" align="right"
              header-align="center"></el-table-column>
            <el-table-column label="截止日期" width="180" align="center">
              <template slot-scope="scope">
                {{ scope.row.IslongTerm ? '永久有效' : scope.row.DeadLine.slice(0, 19) }}
              </template>
            </el-table-column>
            <el-table-column label="提取码" prop="SharingCode" width="100" align="center">
            </el-table-column>
            <el-table-column label="链接是否填充提取码" prop="IsContainParam" width="100" align="center">
              <template slot-scope="scope">
                {{ scope.row.IsContainParam ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" align="center">
              <template slot-scope="scope">
                <div>
                  <el-tooltip class="item" effect="dark" content="复制分享连接" placement="top" :open-delay="450">
                    <el-button type="text" @click.stop="copyRowUrl(scope.row)" key="copyShareLink"
                      v-if="scope.row.IsInvalid">
                      <i class="iconfont icon-fenxianglianjie" style="font-size: 20px;"></i>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="已失效" placement="top" :open-delay="450">
                    <el-button type="text" disabled key="Invalid" v-if="!scope.row.IsInvalid">
                      <i class="iconfont icon-fenxianglianjie" style="font-size: 20px;"></i>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="取消分享" placement="top" :open-delay="450">
                    <el-button type="text" key="closeShare" style="margin-left: 20px" @click="cancelShare(scope.row)">
                      <i class="iconfont icon-quxiaofenxianglianjie" style="font-size: 20px;"></i>
                    </el-button>
                  </el-tooltip>

                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="height: 50px;display: flex;align-items: center;justify-content: right;">
          <el-pagination @size-change="sharehandleSizeChange" @current-change="sharehandleCurrentChange"
            :current-page="sharePagination.currentPage" :page-sizes="[40, 80, 160, 320, 500, 1000]"
            :page-size="sharePagination.pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="sharePagination.total" background>
          </el-pagination>
        </div>
        <div style="height: 50px;display: flex;align-items: center;justify-content: right;">
          <el-button @click="drawer = false">关闭</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import pdf1 from "@/assets/pdf1.png";
import word from "@/assets/WORD.png";
import audio from "@/assets/audio.png";
import video from "@/assets/video.png";
import folderImg from "@/assets/folder.png";
import vueQr from "vue-qr";
import tools from "@/utils/tools";
import moment from "moment";

export default {
  data() {
    return {
      video,
      audio,
      folderImg,
      pickerOptions: {
        disabledDate(time) {
          const dateTime = new Date();
          const startDateTime = dateTime.setDate(dateTime.getDate() - 1);
          const endDateTime = dateTime.setDate(dateTime.getDate() + 30000); //30000为当前日期之后多少天
          return (
            time.getTime() < new Date(startDateTime).getTime() ||
            time.getTime() > new Date(endDateTime).getTime()
          );
        },
        selectableRange:
          //setMinutes,getMinutes限制分 , 年、月、日、时、分、秒同理,+1为数字区间
          //重点就在这个set get, 渔给了, 鱼自己捕吧
          this.parseTime(new Date().setMinutes(new Date().getMinutes() + 1), "{hh}:{ii}:{ss}") + "- 23:59:00",
      },
      // pickerOptions: {
      //   disabledDate(v) {
      //     console.log(moment().valueOf())
      //     return v.getTime() <= Date.now()
      //     return moment(v).valueOf() < moment().valueOf();
      //   }
      // },
      tools,
      checked: false,
      drawer: false,
      cardChecked: [],
      cardList: [

      ],
      qrcode: "",
      qrcodeVisible: false,
      pdf1,
      word,
      viewType: "card",
      Menu: [

      ],
      searchForm: {
        ApplyId: "",
        TaskIds: [],
        timeData: [],
        FileName: ""
      },
      menuKey: '',
      appListData: [],
      taskListData: [],
      pagination: {
        pageSize: 40,
        currentPage: 1,
        total: 0
      },
      loading: false,
      shareForm: {
        DeadLine: "",
        IslongTerm: false,
        SharingCode: "",
        isCode: false,
        IsContainParam: false,
      },
      shareFormLoading: false,
      shareInfoFlag: false,
      shareInfoData: [],
      sharePagination: {
        pageSize: 40,
        currentPage: 1,
        total: 0
      },
      shareInfo: {}
    };
  },
  props: {
    pId: { require: true, type: String },
    parentOBJ: { type: Object }
  },
  components: {
    vueQr
  },
  methods: {
    folderImgData(FileType){
      if(FileType.indexOf("pdf")>=0||FileType.indexOf("PDF")>=0){
        return pdf1
      }
        if(FileType.indexOf("doc")>=0||FileType.indexOf("DOC")>=0||FileType.indexOf("docx")>=0||FileType.indexOf("DOCX")>=0){
        return word
      }
       if(FileType.indexOf("mp3")>=0||FileType.indexOf("MP3")>=0){
        return audio
      }
      if(FileType.indexOf("mp4")>=0||FileType.indexOf("MP4")>=0){
        return video
      }
      return folderImg
    },
    splitFilename(filename) {
      // 查找最后一个'.'的位置  
      const lastIndex = filename.lastIndexOf('.');

      // 如果没有'.'，则整个字符串为文件名，后缀为空  
      if (lastIndex === -1) {
        return [
          filename,
          ''
        ]
      }

      // 截取文件名和后缀  
      const name = filename.slice(0, lastIndex);
      const extension = filename.slice(lastIndex + 1);

      return [
        name,
        extension
      ];
    },
    createAdownload(href) {
      const loading = this.$loading({
        lock: true,
        text: '下载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var a = document.createElement("a");
      a.href = href;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
      loading.close();
    },
    getWord(item) {
      if (item.WordUrl) {
        this.createAdownload(item.WordUrl);
        return;
      }

      this.$http
        .post("/Base_ProjectWork/Base_Project/GetPdfToWord?Id=" + item.ReportId)
        .then((res) => {
          item.loading = false;
          if (res.Success) {
            item.WordUrl = res.Data;
            this.createAdownload(item.WordUrl);
          } else this.$message.error(res.Msg);
        });
    },
    async getExcel(item) {


      var splitFilename = item.FileName.split(".");
      splitFilename.pop();
      var newFilename = splitFilename.join(".");
      await tools.download(this.$rootUrl + "/Base_ProjectWork/Base_Project/GetProjectReport?Id=" + item.ReportId, newFilename, ".xls");

    },
    cancelShare(row) {
      this.$confirm('取消分享后，该条分享记录将被删除，好友将无法再访问此分享链接。 您确认要取消分享吗?', '提示', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.$http.post('/File_Manage/File_SharingInfo/DeleteData', [row.Id]).then(res => {
            if (res.Success) {
              this.$message.success('取消分享成功')
              this.openshareList()
            } else {
              this.$message.error(res.Msg)
            }
          })
        })
    },
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return time_str
    },
    copyRowUrl(row) {
      // console.log(row)
      if (row.IsContainParam) {
        this.copyUrl(`分享链接：${window.location.origin}/File_SharingInfo?shareId=${row.Id}&psd=${row.SharingCode} 复制链接内容后打开浏览器即可查看分享文件`)
      } else {
        this.copyUrl(`分享链接：${window.location.origin}/File_SharingInfo?shareId=${row.Id} 提取码:${row.SharingCode} 复制链接内容后打开浏览器即可查看分享文件`)
      }

    },
    sharehandleSizeChange(val) {
      this.sharePagination.pageSize = val;
      this.openshareList()

    },
    sharehandleCurrentChange(val) {
      this.sharePagination.currentPage = val;
      this.openshareList()

    },
    openshareList() {
      this.drawer = true
      this.$http
        .post("/File_Manage/File_SharingInfo/GetDataListByMoreConditionForHistory", {
          "PageIndex": this.sharePagination.currentPage,
          "PageRows": this.sharePagination.pageSize,
          "SortField": "CreateTime",
          "SortType": "desc",
          "Search": [{
            "Condition": "ProjectId",
            "Keyword": this.pId
          }]
        })
        .then((res) => {
          if (res.Success) {
            this.shareInfoData = res.Data || [];
            this.sharePagination.total = res.Total || 0;
          } else {
            this.$message.error(res.Msg);
          }
        })
    },
    createShareUrl() {


      if (!this.shareForm.isCode) {
        if (!this.validateInput(this.shareForm.SharingCode)) {
          return this.$message.warning('请输入4-6位字母或数字提取码')
        }
      }
      if (!this.shareForm.IslongTerm) {
        if (!this.shareForm.DeadLine) {
          return this.$message.warning('请选择截止日期')
        } else {
          if (this.shareForm.DeadLine <= moment().format('YYYY-MM-DD HH:mm:ss')) {
            return this.$message.warning('当前截止日期已失效，请重新选择截止日期')
          }
        }
      }
      this.shareInfo = {}
      this.shareFormLoading = true
      this.$http
        .post("/File_Manage/File_SharingInfo/SaveData", {
          data: {
            ProjectId: this.pId,
            IslongTerm: this.shareForm.IslongTerm,
            DeadLine: this.shareForm.DeadLine,
            SharingCode: this.shareForm.SharingCode,
            IsContainParam: this.shareForm.IsContainParam,
          },
          FileIds: [...this.FileIds]
        }).then(res => {
          this.shareFormLoading = false
          if (res.Success) {
            this.$message.success('创建成功')
            this.cardList.forEach(item => {
              this.$set(item, 'checked', false)
            })
            this.shareInfoFlag = true
            this.shareInfo = res.Data || {}
            this.qrcode = `${window.location.origin}/File_SharingInfo?shareId=${res.Data.Id || ''}${this.shareForm.IsContainParam ? `&psd=${this.shareInfo.SharingCode}` : ''}`


          } else {
            this.$message.error(res.Msg)
          }
        }).catch(err => {
          this.$message.error(err.Msg)
        })

    },
    validateInput(val) {
      const pattern = /^[a-zA-Z0-9]{4,6}$/;
      return pattern.test(val);
    },
    stopEvent(event) {
      event.stopPropagation();
    },
    rowClick(row) {
      this.$set(row, 'checked', !row.checked)
    },
    copyUrl(text) {
      var input = document.createElement("input"); // 创建input对象
      input.value = text; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    search() {
      this.pagination.currentPage = 1;
      this.getDataList()
    },
    timeChange() {
      this.pagination.currentPage = 1;
      this.getDataList()
    },
    appChange() {
      this.searchForm.TaskIds = []
      this.pagination.currentPage = 1;
      this.getTaskListData()
      this.getDataList()
    },
    taskChange() {
      this.pagination.currentPage = 1;
      this.getDataList()
    },
    getDataList() {
      this.loading = true

      this.$http
        .post("/File_Manage/File_AttachmentsList/GetFileListByConditions", {
          "PageIndex": this.pagination.currentPage,
          "PageRows": this.pagination.pageSize,
          "SortField": "CreateTime",
          "SortType": "desc",
          "Search": {
            "FileType": this.menuKey,
            "ProjectId": this.pId,
            "ApplyId": this.searchForm.ApplyId,
            "TaskIds": this.searchForm.TaskIds,
            "StartDate": this.searchForm.timeData ? this.searchForm.timeData[0] : null,
            "EndDate": this.searchForm.timeData ? this.searchForm.timeData[1] : null,
            "FileName": this.searchForm.FileName,
          }
        })
        .then((res) => {
          this.loading = false
          if (res.Success) {
            res.Data?.forEach(item => {
              this.$set(item, 'checked', false)
              this.$set(item, 'flag', false)
            })
            this.cardList = res.Data || [];
            this.pagination.total = res.Total || 0;
          } else {
            this.$message.error(res.Msg)
          }
        });
    },
    // 左侧菜单点击
    menuChange(item) {
      if (item.DicKey) {
        this.menuKey = item.DicKey
      } else {
        this.menuKey = ''
      }
      this.pagination.currentPage = 1;
      this.getDataList()

    },
    // 列表点击
    cardClick(item) {
      item.checked = !item.checked;
    },
    // 分享
    share(item = null) {
      if (item.Id) {
        this.FileIds = [item.Id]
      } else {
        let arr = this.cardList.filter(item => item.checked)
        if (arr.length == 0) {
          return this.$message.warning('请选择文件')
        }
        this.FileIds = [...arr.map(item => item.Id)]
      }
      this.shareForm = {
        DeadLine: "",
        IslongTerm: false,
        SharingCode: "",
        isCode: false,
        IsContainParam: false,
      }
      this.qrcode = ''
      this.shareInfoFlag = false
      this.qrcodeVisible = true
      console.log('分享')
    },
    // 下载
    download(item) {
      const loading = this.$loading({
        lock: true,
        text: '下载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const elink = document.createElement("a");
      elink.download = item.FileName;
      elink.style.display = "none";
      elink.href = item.FileWebURL;
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
      loading.close();
      // // tools.download(item.FileWebURL, item.FileName)
      // window.open(item.FileWebURL)
      // console.log('下载')
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getDataList()

    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getDataList()

    },
    menuTaskChange(e, index) {
      this.menuKey = index;
    },
    getTypeData() {
      this.$http
        .post("/Base_BasicData/Base_DictionaryMain/GetDataListByDicKey", {
          Condition: "DicKey",
          Keyword: 'FileTypeList',
        }).then(res => {
          if (res.Success) {
            this.Menu = res.Data || []
          }
        })

    },
    //获取应用名称
    getAppListData() {
      this.$http
        .post("/Base_BasicData/Base_Apply/GetDataList", {})
        .then((res) => {
          if (res.Success) {

            this.appListData = res.Data?.filter(item => item.ApplyCode != '10003') || []
          }
        });
    },
    //获取任务列表
    getTaskListData() {
      this.$http
        .post("/Base_TaskWork/Base_Task/GetDataListByMoreCondition", {
          Search: {
            Conditions: [
              {
                Condition: "ProjectId",
                Keyword: this.pId
              },
              {
                Condition: "AppyId",
                Keyword: this.searchForm.ApplyId,
              },
            ],
          },
        })
        .then((res) => {
          if (res.Success) {
            this.taskListData = res.Data;
          } else {
            this.$message.error(res.Message);
          }
        })
    },
  },
  mounted() { },
  created() {
    // console.log(this.pId)
    this.getAppListData(),
      this.getTypeData()
    this.getTaskListData()
    this.getDataList()
  },
  computed: {},
  watch: {
    'shareForm.IslongTerm': {
      handler(val) {
        if (val) {
          this.shareForm.DeadLine = ""
        }
      }
    },
    'shareForm.isCode': {
      handler(val) {
        if (val) {
          this.shareForm.SharingCode = ""
        }
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.file {
  padding-top: 10px;
}

.Menu {
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  transition: all 70ms ease 0s;

  .menuItem {
    font-size: 14px;
    padding: 7px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background: #fae7e7;
      color: #d1100b;
      font-weight: 600;
    }
  }

  .menuActive {
    background: #fae7e7;
    color: #d1100b;
    font-weight: 600;
  }

  .shrink {
    position: absolute;
    bottom: 10px;
    right: -12px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;

    &:hover {
      color: #d1100b;
      border-color: #d1100b;
    }
  }
}

.main-container {
  background: #fff;
  overflow: hidden;
  height: calc(100vh - 90px);

  .aside {
    height: calc(100vh - 90px);
    background-color: #fff;
    border-right: 1px solid #ededed;
  }

  .header {
    height: auto;
    padding: 10px;
    border-bottom: 1px solid #ededed;
  }

  .center {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

.proListCard {
  padding: 0;
  position: relative;
  // box-sizing: border-box;
  padding: 3px;
  border: 2px solid #ccc;
  width: 185px;
  height: 165px;
  margin: 5px;

  &:hover {
    box-shadow: 5px 5px 5px rgb(38 38 38 / 50%),
      5px 5px 10px rgb(38 38 38 / 30%);

    .delIcon {
      opacity: 1;
    }

    .star {
      opacity: 1;
    }
  }

  .proListImg {
    width: 100%;
    height: 90px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

}



.proListCard2 {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 3px;
  border: 2px solid #ccc;
  display: flex;
  position: relative;

  &:hover {
    box-shadow: 5px 5px 5px 5px rgb(38 38 38 / 50%),
      5px 5px 10px rgb(38 38 38 / 30%);

    color: #d1100b;
  }

  .proListImg {
    width: 200px;
    height: 90px;
  }
}

.proListCardActive {
  border: 2px solid #d1100b !important;
}

.ellipsis {
  overflow: hidden; //超出隐藏

  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）
  word-wrap: break-word;
  word-break: normal;
  -webkit-line-clamp: 2; //内容限制的行数
}

.ellipsis4 {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 4; //内容限制的行数
}

.ellipsis1 {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 1; //内容限制的行数
}

::v-deep.el-table thead tr th {
  padding: 10px 0px !important;
  background-color: #F5F7FA;
}

::v-deep .el-table__row--striped {
  background-color: #F5F7FA !important;
}
</style>